import * as React from "react"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link as RouterLink } from "react-router-dom"
import { NavigateNext } from "@mui/icons-material"

const breadcrumbNameMap = {
    "/corporates": "Corporates",
    "/drivers": "Drivers",
    "/personals": "Personals",
    "/roles": "Roles",
    "/users": "Users",
    "/street-pickup": "Street Pickup",
    "/ride-commission": "Ride Commission",
    "/plus-commission": "Plus Commission",
    "/trips": "Trips",
    "/family-package": "Family Package",
    "/coa": "Chart of Accounts",
    "/loyalties": "Loyalties",
    "/transactions-alert": "Transactions Alert",
    "/notifications": "Notifications",
    "/receipts": "Receipts",
    "/packages": "Packages",
    "/agents": "Agents",
    "/efloat-topup-transactions": "Efloat TopUp Transactions",
}

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />
}

function BreadCrumbPaths({ to, pathnames }) {
    const first = `/${pathnames[0]}`

    return first !== to ? (
        <div>{pathnames[1]}</div>
    ) : (
        <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
        </LinkRouter>
    )
}

function LastItem({ to, pathnames }) {
    const lastWord = pathnames[pathnames.length - 1]

    return pathnames.length > 1 ? (
        <Typography color="black" fontWeight="bold" fontSize={20} key={to}>
            {(lastWord.charAt(0).toUpperCase() + lastWord.slice(1)).replace(
                "-",
                " "
            )}
        </Typography>
    ) : (
        <Typography color="black" fontWeight="bold" fontSize={20} key={to}>
            {breadcrumbNameMap[to]}
        </Typography>
    )
}

function Page({ location }) {
    const pathnames = location.pathname.split("/").filter((x) => x)

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            maxItems={2}
            separator={<NavigateNext fontSize="small" />}
        >
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join("/")}`

                return last ? (
                    <LastItem to={to} pathnames={pathnames} />
                ) : (
                    <BreadCrumbPaths to={to} pathnames={pathnames} />
                )
            })}
        </Breadcrumbs>
    )
}
export default Page
